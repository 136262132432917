@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom 按钮 */
@layer utilities {
  .button-main {
    @apply bg-brand hover:bg-brand  active:shadow-none  disabled:bg-gray-200 disabled:text-tertiary font-averta font-semibold rounded-xl text-base text-white capitalize shadow-none;
  }
  .button-default {
    @apply font-averta font-semibold rounded-xl text-base text-primary  capitalize shadow-none border border-solid border-primary;
  }
  .button-back {
    @apply bg-white font-averta font-semibold rounded-xl text-base capitalize shadow-none  text-primary border border-solid border-primary disabled:border-gray-200 disabled:text-tertiary;
  }
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    letter-spacing: 0;
  }
  :root {
    --doc-vh: calc(1vh); // 设置--doc-vh默认值，防止使用该变量的地方没有初始高度
  }
}

:root,
html,
body {
  font-family:
    var(--font-averta),
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  letter-spacing: 0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  letter-spacing: 0;
}

#__nextBody,
#__next,
.layout {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
  }
}

//mui pagination
.MuiPagination-ul {
  flex-wrap: nowrap;
  justify-content: center;
}

.MuiPaginationItem-root {
  font-weight: bold;
}

// home page swiper styles
.home-swiper {
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #050a22;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    display: none;
  }

  @media screen and (max-width: 1199px) {
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      display: none;
    }
  }
}

#propertyRichText {
  h2 {
    font-family: var(--font-averta) !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #050a22 !important;
  }

  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #75798d !important;
    line-height: 20px !important;
    font-family: var(--font-averta) !important;
  }

  span {
    font-family: var(--font-averta) !important;
  }
}

// room page style
.room-page {
  .MuiPagination-ul {
    margin-top: 27px;
  }
}

// cleadnr
.tp-calendar {
  width: 301px;
  font-size: 14px;
  font-family: 'Averta CY';

  .sc-iBkjds.range-select {
    background-color: #050a22;
    box-shadow: none;
  }

  .sc-iBkjds.range-select:hover {
    background-color: #050a22;
  }

  .sc-iBkjds {
    color: #75798d;
  }

  .sc-iBkjds:hover {
    background-color: #ccc;
  }

  .sc-gKXOVf,
  .sc-bczRLJ {
    margin: 0;
    border: none;
  }

  .sc-bczRLJ {
    height: auto;
  }

  .sc-bczRLJ > div {
    width: 40px;
    height: 40px;
    margin: auto;
    background-color: #050a22;
  }

  .sc-bczRLJ > div:hover {
    background-color: #050a22;
  }

  .sc-dkzDqf,
  .cvpakU {
    height: 40px;
    background-color: #fff;

    p {
      color: #050a22;
      font-size: 16px;
      font-weight: 700;
      pointer-events: none;
    }
  }

  .sc-eCYdqJ {
    height: auto;
  }

  .sc-dkzDqf svg,
  .cvpakU svg {
    color: #050a22;
  }

  .sc-gsnTZi {
    height: 200px;
    top: 40px;
  }
}

// location swiper
.location-swiper {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #050a22;
  --swiper-navigation-size: 8px;

  --swiper-pagination-color: #75798d;
  --swiper-pagination-bullet-width: 4px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.8;

  .swiper-button-next,
  .swiper-button-prev {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
    display: none;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }

    :after {
      color: #050a22;
      font-size: 12px;
    }
  }

  .swiper-pagination {
    display: none;
  }

  &:hover .swiper-button-next {
    display: flex;
  }

  &:hover .swiper-button-prev {
    display: flex;
  }

  &:hover .swiper-button-disabled {
    display: none;
  }

  @media screen and (max-width: 1199px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }

    .swiper-button-disabled {
      display: none;
    }
  }
}

.location-swiper-mweb {
  --swiper-pagination-color: #40444e;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
  --swiper-pagination-bullet-inactive-color: #eceef7;
  --swiper-pagination-bullet-inactive-opacity: 0.8;

  // .swiper-horizontal > .swiper-pagination-bullets,
  // .swiper-pagination-bullets.swiper-pagination-horizontal,
  // .swiper-pagination-custom,
  // .swiper-pagination-fraction {
  //   bottom: 1px;
  // }

  .swiper-pagination {
    display: block;
  }
}

.detail-top-swiper {
  .swiper-button-next,
  .swiper-button-prev {
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
  }

  .swiper-pagination {
    display: none;
  }

  &:hover .swiper-pagination {
    display: block;
  }
}

.location-amenities-swiper {
  @extend .location-swiper;
  --swiper-pagination-color: #e0e2ef;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
  --swiper-pagination-bullet-inactive-color: #e74f4f;
  --swiper-pagination-bullet-inactive-opacity: 0.8;
}

.map-swiper {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 10px;
}

.location-briefcard-swiper {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #050a22;
  --swiper-navigation-size: 8px;

  --swiper-pagination-color: #75798d;
  --swiper-pagination-bullet-width: 4px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.8;
  --swiper-navigation-top-offset: 45%;

  height: 589px;
  // padding: 0px 105px 32px !important;

  .swiper-button-next,
  .swiper-button-prev {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #b7bbc2;
    opacity: 0.6;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));

    &:after {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .swiper-pagination {
    font-weight: 600;
    font-size: 16px;
    font-family: 'averta';
  }
}

//home-everything-swiper
.everything-swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #050a22;
}

//home-testimonials-scroll
.testimonials-overflow::-webkit-scrollbar {
  display: none;
}

#price-slider {
  .MuiSlider-valueLabelOpen {
    background: #fff;
    padding: 0;

    &:before {
      display: none;
    }
  }

  .MuiSlider-valueLabelLabel {
    color: #000;
    font-size: 16px;
  }
}

// partner-swiper
.partner-swiper {
  .swiper-pagination-bullet-active {
    background-color: #e74f4f;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: calc(50% - 56px);
    right: 0;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 12px;
    color: #050a22;
  }
}

.seoContent {
  font-family: 'Averta CY';

  h2 {
    font-size: 20px;
    color: #444;
    font-weight: bold;
  }
}

// mui-date-picker styles
.mui-date-picker {
  .MuiButtonBase-root {
    width: 40px;
    height: 40px;
  }

  .MuiCalendarPicker-viewTransitionContainer .Mui-selected:hover,
  .MuiCalendarPicker-viewTransitionContainer .Mui-selected {
    background-color: #050a22;
  }
}

// video-swiper
.video-swiper {
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

#nprogress .bar {
  background: #050a22;
}

#nprogress .peg {
  box-shadow:
    0 0 10px #050a22,
    0 0 5px #050a22;
}

#inputCalendar::-webkit-input-placeholder {
  color: #afabab;
}

//referral
.referral-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination-bullet {
    background-color: #ccc;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
}

.MuiAutocomplete-popper .MuiAutocomplete-paper {
  margin-top: 16px;
  border-radius: 12px;

  .MuiAutocomplete-option {
    height: 56px;
    padding: 0 24px;
    line-height: 56px;
  }

  .MuiAutocomplete-option:hover {
    background: #eceef7;
  }

  .MuiAutocomplete-listbox {
    max-height: 300px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.2s;
}

.inputPlaceholder {
  input[type='text']::-webkit-input-placeholder {
    color: #75798d;
    opacity: 1;
  }

  textarea::-webkit-input-placeholder {
    color: #75798d !important;
    opacity: 1;
  }

  .MuiNativeSelect-root::before {
    border-width: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e0e2ef;
  }
}

.mobile-header-search {
  input[type='text']::-webkit-input-placeholder {
    color: #75798d;
    font-family: 'Averta CY';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

#header-menu-appbar {
  .MuiPopover-paper {
    border-radius: 12px;
  }

  textarea::-webkit-input-placeholder {
    color: #75798d !important;
    opacity: 1;
  }

  .MuiNativeSelect-root::before {
    border-width: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e0e2ef;
  }
}

// .unit-detail::-webkit-scrollbar {
//   width:6px
//   background: #E0E2EF;
// }

.ver_code_input_main {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .input_item {
    // width: 42px;
    // height: @mainHeight;
    // line-height: @mainHeight;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    // border-radius: 4px;
    margin-right: 5px;
    // &:valid{
    //   border-bottom: 2px solid #BA382C;
    // }
    // &:not(:first-child) {
    //   margin-left: 30px;
    // }
    // font-size: 48px;
    color: #000;
    text-align: center;

    span {
      display: inline-block;
      width: 1px;
      height: 36px;
      background: #050a22;
      border-radius: 1px;
      animation: light 0.5s 0.2s linear infinite alternate;
    }

    @keyframes light {
      0% {
        background: #050a22;
      }

      100% {
        background: transparent;
      }
    }
  }

  .valuable {
    border-bottom: 1px solid #050a22;
  }

  input {
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    outline: none;
    color: transparent;
    text-shadow: 0 0 0 transparent;
    background-color: transparent;
    border: transparent;
  }

  input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    -moz-appearance: textfield;
  }
}

.chat-gpt-msg-container {
  a {
    color: #2a61ff;
    font-weight: 600;
    text-decoration: underline;
  }
}

.chat-gpt-msg-container-sm {
  a {
    color: #eaf0ff;
    font-weight: 600;
    text-decoration: underline;
  }
}

.landing {
  .MuiTextField-root {
    width: 100%;
  }

  .Mui-focused {
    .placeholder {
      display: none !important;
    }
  }
}

// blog page style
.posts-page {
  .Mui-selected {
    background-color: #050a22 !important;
    color: #fff;
  }

  .MuiPagination-ul {
    margin-top: 45px;
  }

  .swiper-pagination {
    bottom: -52px !important;
  }
}

//blog
.blog {
  --swiper-theme-color: #004d34;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #75798d;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 10px;

  /* 设置按钮大小 */
  //swiper
  .swiper-button-next,
  .swiper-button-prev {
    background: #fff;
    padding: 18px;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eceef7;
    font-weight: bold;
  }

  .MuiDivider-root::before,
  .MuiDivider-root::after {
    border-top: thin solid #d6d8e7 !important;
  }
}

#blog {
  .swiper-button-prev {
    left: -20px;
  }

  .swiper-button-next {
    right: -20px;
  }

  .swiper-pagination {
    bottom: -52px !important;
  }

  // blog detail page style
  .MuiDivider-root {
    width: 460px;
    margin: auto;
  }
}

#blog-moblie {
  .swiper-button-next,
  .swiper-button-prev {
    top: auto;
    bottom: -65px !important;
    z-index: 99;
  }

  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 0px;
  }

  .MuiDivider-root {
    width: 100%;
  }
}
.green-point{
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: 3px 4px;
    background: #226643;
  }
}